.work-container {
    position: relative;
    box-sizing: content-box;
    margin: 0 auto;
}


.wrap-work-container {
    min-height: 100vh;
}



.image a {
    display: flex;
    justify-content: center;
    align-items: center;
}