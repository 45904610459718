.card {
    padding: 100px;
    margin: 0 auto;
    margin-top: 100px;
    display:block;
    width: max-content;
    height: max-content;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 10px;
 
}

@media screen and (max-width: 600px) {
    .card {
        
        width: max-content;
        height: max-content; 

    }
}