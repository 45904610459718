.footer {
    background-color: #212a32;
    height: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 5rem;
}

.gitfoot {
    display:flex;
    justify-content: center;
    gap: 40px;
}