.mainmenu {
    position: relative;
    background-color: #212a32;
}

@media screen and (max-width: 600px) {
    .mainmenu ul {
        
        list-style-type: none;
        display: block;
        margin-top: 0;
        margin-bottom: 0;

    }
}


.HomeLink {
    text-decoration: none;
    color: white;
}

.HomeLink:hover {
    color: rgb(12, 192, 135);
}

.About {
    text-decoration: none;
    color: white;
    transition: background-color 1s;
}

.About:hover {
    color: rgb(12, 192, 135);
}

.About:focus,
.About:active {
    transition: none;
}

.Contact {
    text-decoration: none;
    color: white;
}

.Contact:hover {
    color: rgb(12, 192, 135);
}

.Work {
    text-decoration: none;
    color: white;
}

.Work:hover {
    color: rgb(12, 192, 135);
}

ul {
    font-family: 'Quicksand', sans-serif;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    display:flex;
    padding: 30px;
    margin-top: 0;
    margin-bottom: 0;
    gap: 200px;
    flex-wrap:wrap;
}