.contact-wrap-form {
    min-height: 100vh;
    background-color: #212a32;
    padding-bottom: 10.5rem;

}

.form {
    box-sizing: content-box;
    margin-bottom: 10rem;
    color: white;
}

.contact-paragraph{
    color: white;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    margin-bottom: 70px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .contact-paragraph {
        
        font-size: 16px; 
        font-family: 'Lato', sans-serif;
        text-align: center;
    }
}


.contact-header {
    color: white;
    padding-top: 5%;
    padding-bottom: 5%;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}


