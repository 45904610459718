.main-container {
    position: relative;
    /* border: rgb(65, 255, 36) solid 2px; */
}

.main-wrap-form{
    background-color: #212a32;
    padding-bottom: 30rem;
    min-height: 100vh;
}

.info-container {
    font-size: 60px;
    width:-webkit-fit-content;
    flex-wrap: wrap;
    color: white;
    font-family: 'Sulphur Point', sans-serif;
    animation: fadeIn 5s;
    margin: 0 auto;
    margin-top: 300px;
}

@media screen and (max-width: 600px) {
    .info-container {
        
        font-size: 30px; 

    }
}

@keyframes fadeIn {
    from {bottom: 0px;}
    to {top:300px;}
    0% { opacity: 0; }
    100% { opacity: 1; }
}