.about {
    color: white;
}

.abody {
    min-height: 100vh;
}

.study {

    column-count: 2;
    width: fit-content;
    padding: 50px;
    border:  rgb(12, 192, 135) solid 10px;
    border-radius: 75px;
    margin: 0 auto;
    margin-bottom: 80px;
}

li {
    
    list-style: none;
    padding-top: 30px;
}



@media screen and (max-width: 600px) {
    .study {
        
        display: flex;

    }
}

.information {
    
    margin: 0 auto;
    margin-top: 200px;
    margin-bottom: 100px;
    width: 50%;
    height: 100%;
}


.name h5{
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 40px;
    font-size: 46px;
}

.intro p {
    font-size: 24px;
    font-family: 'Lato', sans-serif;
}

.resume {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    padding-bottom: 8%;
}

.button {
    background-color: rgb(12, 192, 135);
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;
    border: none;
    color: rgb(0, 0, 0);
    padding: 15px 32px;
    
}

.button:hover {
    color: white;
}