.link {
    padding: 200px;
    margin-left: 750px;
    margin-top: 100px;
    display: flex;
    position: static;
    width: 50px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    justify-content: center;
}